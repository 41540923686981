import './App.css';
import './index.css';
import { useState, useEffect, memo } from 'react'
import { motion, useAnimation } from 'framer-motion'

const FallingPumpkin = memo(() => {
  const controls = useAnimation()
  const startX = Math.random() * 100
  const endX = startX + (Math.random() * 20 - 10) // Small horizontal movement
  const size = 32 + Math.random() * 32 // Size between 32px and 64px

  useEffect(() => {
    const animate = async () => {
      while (true) {
        await controls.start({
          y: ['0vh', '100vh'],
          x: [`${startX}vw`, `${endX}vw`],
          rotate: [0, 360],
          transition: {
            duration: 8 + Math.random() * 4,
            ease: 'linear',
            repeat: Infinity,
            repeatType: 'loop',
          },
        })
      }
    }
    animate()
  }, [controls, startX, endX])

  return (
    <motion.img
      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/citrouille-removebg-iMHDQbkU7PgYb7VHZvgybF4O7TpyEc.png"
      className="absolute pointer-events-none"
      style={{
        left: `${startX}vw`,
        width: `${size}px`,
        height: `${size}px`,
      }}
      animate={controls}
      alt="Falling pumpkin"
    />
  )
})

FallingPumpkin.displayName = 'FallingPumpkin'

function App() {


  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  const handleBuyClick = (e) => {
    e.preventDefault()
    window.open('https://app.uniswap.org/swap?outputCurrency=0x9A7219D3759Dda67ef8036D0ed8ceC44B04b47f4', '_blank', 'noopener,noreferrer')
  }

  const handleDexScreenerClick = (e) => {
    e.preventDefault()
    window.open('https://dexscreener.com/ethereum/0x9A7219D3759Dda67ef8036D0ed8ceC44B04b47f4', '_blank', 'noopener,noreferrer')
  }

  const handleTelegramClick = (e) => {
    e.preventDefault()
    window.open('https://t.me/realtrumpkinportal', '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="min-h-screen bg-black text-orange-100 overflow-hidden relative">
      <style jsx global>{`
        @import url('https://fonts.cdnfonts.com/css/bloody-frozen');
      `}</style>

      {/* Falling pumpkins */}
      <div className="fixed inset-0 pointer-events-none">
        {[...Array(20)].map((_, index) => (
          <FallingPumpkin key={index} />
        ))}
      </div>

      {/* Main content */}
      <div className="container mx-auto px-4 py-16 relative z-10">
        <header className="text-center mb-12">
          <h1 className="text-8xl font-bold mb-4 text-orange-400 drop-shadow-lg" style={{ fontFamily: "'Bloody Frozen', sans-serif" }}>
            TRUMPKIN
          </h1>
          <p className="text-4xl mb-8 text-orange-200" style={{ fontFamily: "'Bloody Frozen', sans-serif" }}>
            MAKE PUMPKIN GREAT AGAIN
          </p>
        </header>

        <div className="flex justify-center mb-12">
          <motion.img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/twitter-profile-remove-5FOYxX1TXzz5qwcLOgnL5FT6LgrZ8q.png"
            alt="Trumpkin"
            className="w-64 h-64"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          />
        </div>

        <div className="text-center mb-12">
          <a
            href="https://app.uniswap.org/swap?outputCurrency=0x76e222b07c53d28b89b0bac18602810fc22b49a8"
            onClick={handleBuyClick}
            className="inline-block bg-orange-500 hover:bg-orange-600 text-black font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer"
          >
            Buy TRUMPKIN
          </a>
        </div>

        {/* Social media and DEXScreener links */}
        <div className="flex justify-center space-x-6 mb-8">
          <a 
            href="https://x.com/realTrumpkinETH" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-orange-300 hover:text-orange-100 cursor-pointer" 
            aria-label="Twitter"
            onClick={(e) => {
              e.preventDefault()
              window.open('https://x.com/realTrumpkinETH', '_blank', 'noopener,noreferrer')
            }}
          >
            <svg className="w-8 h-8" viewBox="0 0 24 24" fill="currentColor">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
          </a>
          <a 
            href="https://t.me/realtrumpkinportal"
            onClick={handleTelegramClick}
            className="text-orange-300 hover:text-orange-100 cursor-pointer" 
            aria-label="Telegram"
          >
            <svg className="w-8 h-8" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
            </svg>
          </a>
          <a 
            href="https://dexscreener.com/ethereum/0x22741e1153399a23Ebb27db514742bb14b9F0419"
            onClick={handleDexScreenerClick}
            className="text-orange-300 hover:text-orange-100 cursor-pointer" 
            aria-label="DEXScreener"
          >
            <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" fillRule="evenodd" viewBox="0 0 252 300" focusable="false">
              <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197" />
              <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z" />
            </svg>
          </a>
        </div>

        {/* Information part */}
        <div className="max-w-2xl mx-auto bg-orange-900 bg-opacity-50 rounded-lg p-6 mb-12">
          <h2 className="text-3xl font-bold mb-4 text-orange-300" style={{ fontFamily: "'Bloody Frozen', sans-serif" }}>About TRUMPKIN</h2>
          <ul className="space-y-2 text-orange-100">
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>Total Supply: 1,000,000 TRUMK</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <span>Ticker: $TRUMK</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
              </svg>
              <span>CA: 0x9A7219D3759Dda67ef8036D0ed8ceC44B04b47f4</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
              </svg>
              <span>Blockchain: Ethereum</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <span>No Taxes, No Fees, No Nonsense</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
              </svg>
              <span>Liquidity Locked</span>
            </li>
            <li className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-orange-300 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
              </svg>
              <span>Fair Launch, No Presale</span>
            </li>
          </ul>
        </div>
      </div>

      {/* Floating jack-o'-lantern cursor */}
      <motion.div
        className="fixed pointer-events-none z-50"
        animate={{ x: mousePosition.x - 16, y: mousePosition.y - 16 }}
      >
        <img
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/twitter-profile-ZPzlNT2TDyYFkC70Y9r4HMkalbd5Dt.png"
          alt="Cursor"
          className="w-8 h-8"
        />
      </motion.div>
    </div>
  );
}

export default App;
